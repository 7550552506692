// Footer

.main__footer {
    padding: 5px 0;
    background: $black;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size(12);

        li {
            margin: 0 5px;
        }

        a, p {
            margin: 0;
            font-weight: 600;
            color: $white;
        }
    }
}