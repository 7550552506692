// header

.main__header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    background: $white;
}

.navbar {
    padding: 0;
}

.navbar-brand {
    height: 60px;
    padding: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 800;
    @include font-size(20);
    color: $black;
    margin-left: 60px;

    &:hover {
        color: $black;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 0;
        @include font-size(26);
    }

    @include media-breakpoint-up(xl) {
        @include font-size(30);
    }

    img {
        height: 45px;
        width: auto;
    }
}

.nav-link {
    @include font-size(16);
    text-transform: uppercase;
    font-weight: 400;
    color: $black;

    &:hover {
        color: $primary-color;
    }
}

.navbar-nav .show >.nav-link,
.navbar-nav .active > .nav-link,
.navbar-nav .nav-link.show,
.navbar-nav .nav-link.active {
    color: $primary-color;
}

.navbar-toggler {
    padding: 0;
    position: absolute;
    z-index: 10;
    top: 0;
    left: -15px;
    border: none;

    &:focus, &:visited, &:active {
        outline: none !important;
        box-shadow: none !important;
    }

    .ham {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: transform 400ms;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .hamRotate.active {
        transform: rotate(45deg);
    }
    .hamRotate180.active {
        transform: rotate(180deg);
    }
    .line {
        fill:none;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke: $black;
        stroke-width:5.5;
        stroke-linecap:round;
    }

    .ham7 .top {
        stroke-dasharray: 40 82;
      }
    .ham7 .middle {
        stroke-dasharray: 40 111;
    }
    .ham7 .bottom {
        stroke-dasharray: 40 161;
    }
    .ham7.active .top {
        stroke-dasharray: 17 82;
        stroke-dashoffset: -62px;
    }
    .ham7.active .middle {
        stroke-dashoffset: 23px;
    }
    .ham7.active .bottom {
        stroke-dashoffset: -83px;
    }
}