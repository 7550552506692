// Home

.main-wrapper--home {
    padding: 0;
    animation: background 0.5s 1;
    @keyframes background {
        0% {
            background: $primary-color;
        }
        100% {
            background: #ffffff;
        } 
    }
}

.main__home {
    .main__home__section {
        min-height: 100vh;

        .container-fluid {
            min-height: 100vh;
        }

        .home__section__content {
            width: 100%;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0;
        }
    }

    .main__home__banner {
        background: url("../img/bg-banner--mobile.jpg") no-repeat center;
        background-size: cover;
        position: relative;

        @include media-breakpoint-up(lg) {
            background: url("../img/bg-banner.jpg") no-repeat center fixed;
            background-size: cover;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: linear-gradient(0deg, $black, rgba(255, 255, 255, 0));
        }

        .home__banner__content {
            position: relative;
            z-index: 1;
            h1 {
                animation: fall 1.2s 1;
                opacity: 1;
            }
            @keyframes fall {
                0% {
                    transform: scale(3);
                    opacity: 0;
                }
                50% {
                    transform: scale(0.95);
                    opacity: 1;
                }
                75% {
                    transform: scale(1.05);
                    opacity: 1;
                }
                100% {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            img {
                width: 200px;
                height: auto;

                @include media-breakpoint-up(sm) {
                    width: 250px;
                    height: auto;
                }

                @include media-breakpoint-up(md) {
                    width: 300px;
                    height: auto;
                }

                @include media-breakpoint-up(lg) {
                    width: 350px;
                    height: auto;
                }
            }
        }
    }

    .main__home__presentation {
        background: $third-color;

        .home__presentation__content {
            .main__description {
                max-width: 1000px;
                text-align: center;

                h2 {
                    font-weight: 400;
                    @include font-size(25);
                    @include line-height(35);
                    margin-bottom: 50px;

                    @include media-breakpoint-up(md) {
                        font-weight: 300;
                        @include font-size(40);
                        @include line-height(50);
                    }

                    strong {
                        font-weight: 700;
                        color: $primary-color;
                    }
                }

                p {
                    @include font-size(20);
                    @include line-height(26);
                    font-weight: 400;

                    @include media-breakpoint-up(md) {
                        font-weight: 300;
                    }

                    strong {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    .main__home__skills {
        .home__skills__content {
            .home__skills__content__list {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
            }

            .home__skills__entry {
                flex-shrink: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 50px 0;

                @include media-breakpoint-up(md) {
                    width: 48%;
                }

                @include media-breakpoint-up(lg) {
                    width: 30%;
                }

                svg {
                    height: 50px;
                    fill: $primary-color;
                    margin-bottom: 15px;
                }

                h3 {
                    font-weight: 400;
                    text-align: center;
                    @include font-size(32);
                    @include line-height(34);

                    @include media-breakpoint-up(md) {
                        font-weight: 300;
                    }
                }

                p {
                    margin-bottom: 0;
                    text-align: center;
                    @include font-size(18);
                    @include line-height(22);
                }
            }
        }
    }

    .main__home__works {
        background: $third-color;

        .home__works__content {
            .home__works__content__list {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
            }

            .home__works__entry {
                flex-shrink: 0;
                margin: 15px;
                width: 100%;
                cursor: pointer;

                @include media-breakpoint-up(sm) {
                    width: 44%;
                }

                @include media-breakpoint-up(lg) {
                    width: 28%;
                }

                @include media-breakpoint-up(xl) {
                    width: 22%;
                }

                &:after {
                    padding-top: 100%;
                    display: block;
                    content: '';
                }
            }

            .card-container, .front, .back {
                width: 100%;
                height: 100%;
            }

            .card-container {
                position: relative;
            }

            .front, .back {
                position: absolute;
                top: 0;
                left: 0;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                overflow: hidden;
                transition: transform .8s ease;
                padding: 15px;
                background: $white;
                border-radius: 10px;
	            box-shadow: 0 1rem 2rem rgba(0, 0, 0, .25);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 90%;
                    height: auto;
                    max-width: 100%;
                }

                h3 {
                    font-weight: 400;
                    font-size: 7vw;
                    line-height: 8vw;
                    text-align: center;
                    margin-bottom: 15px;

                    @include media-breakpoint-up(sm) {
                        font-size: 3vw;
                        line-height: 3.5vw;
                    }

                    @include media-breakpoint-up(md) {
                        font-weight: 300;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 2vw;
                        line-height: 2.5vw;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 1.5vw;
                        line-height: 1.8vw;
                    }

                    @media screen and (min-width: 1600px) {
                        @include font-size(24);
                        @include line-height(28);
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 5vw;
                    line-height: 6vw;
                    text-align: center;

                    @include media-breakpoint-up(sm) {
                        font-size: 2vw;
                        line-height: 3vw;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 1.5vw;
                        line-height: 2vw;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 1vw;
                        line-height: 1.5vw;
                    }

                    @media screen and (min-width: 1600px) {
                        @include font-size(18);
                        @include line-height(24);
                    }
                }
            }

            .back {
                transform: rotateY(180deg);
            }

            .card-container:hover .front {
                transform: rotateY(-180deg);
            }

            .card-container:hover .back {
                transform: rotateY(0deg);
            }
            
            .card-container {
                perspective: 75rem;
            }
        }
    }

    .main__home__contact {
        background: $primary-color;

        .home__contact__content {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                justify-content: space-between;
            }

            .contact__description {
                flex-shrink: 0;
                width: 100%;
                color: $white;
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    width: 48%;
                    margin-bottom: 0;
                }

                h2 {
                    font-weight: 400;
                    @include font-size(40);
                    @include line-height(44);
                    border-bottom: 2px solid $white;
                    margin-bottom: 30px;
                    padding-bottom: 15px;

                    @include media-breakpoint-up(md) {
                        font-weight: 300;
                    }
                }

                h3 {
                    font-weight: 400;
                    @include font-size(26);
                    @include line-height(28);
                    margin-bottom: 3px;
                    margin-top: 30px;

                    @include media-breakpoint-up(md) {
                        font-weight: 300;
                    }
                }

                p, a {
                    @include font-size(18);
                    @include line-height(20);
                }

                a {
                    color: $white;
                }

                .partners {
                    margin-top: 30px;
                    .partners__entry {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
    
                        img {
                            flex-shrink: 0;
                            width: 50px;
                            height: auto;
                            margin-right: 15px;
                        }
    
                        p {
                            @include font-size(16);
                            @include line-height(18);
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .contact__map {
                flex-shrink: 0;
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 48%;
                }
            }
        }
    }
}