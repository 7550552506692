// Breadcrumb

.breadcrumb {
    padding: 10px 0;
    background: transparent;
    @include font-size(12);

    .active {
        color: $white;
    }
}