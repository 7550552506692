@import "node_modules/bootstrap/scss/bootstrap";

// Common
@import "common/variables";
@import "common/mixins";
@import "common/fonts";
@import "common/global";

// // Components
@import "components/buttons";
@import "components/breadcrumb";

// // Layouts
@import "layouts/header";
@import "layouts/footer";
@import "layouts/home";
@import "layouts/page";
