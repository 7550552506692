// Fonts

@font-face {
    font-family: 'Lato';
    font-weight: 100;
    font-style: normal;
    src: url('../img/Lato-Thin.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-weight: 100;
    font-style: italic;
    src: url('../img/Lato-ThinItalic.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-weight: 300;
    font-style: normal;
    src: url('../img/Lato-Light.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-weight: 300;
    font-style: italic;
    src: url('../img/Lato-Light-Italic.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-style: normal;
    src: url('../img/Lato-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-style: italic;
    src: url('../img/Lato-Italic.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-weight: 700;
    font-style: normal;
    src: url('../img/Lato-Bold.ttf');
    font-display: swap;
}

 @font-face {
    font-family: 'Lato';
    font-weight: 700;
    font-style: italic;
    src: url('../img/Lato-BoldItalic.ttf');
    font-display: swap;
 }

 @font-face {
    font-family: 'Lato';
    font-weight: 900;
    font-style: normal;
    src: url('../img/Lato-Black.ttf');
    font-display: swap;
 }

 @font-face {
    font-family: 'Lato';
    font-weight: 900;
    font-style: italic;
    src: url('../img/Lato-BlackItalic.ttf');
    font-display: swap;
 }
 