// Variables

// Typo
$main-typo:    'Lato', sans-serif;

// Colors
$white:             #ffffff;
$black:             #000000;
$primary-color:     #746083;
$second-color:      #242552;
$third-color:       #f2f2f2;
$fourth-color:      #8fc02c;
$fifth-color:       #666666;
$text-color:        #2D353B;
$shadow-color:      #2e5eb0;