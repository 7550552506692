// Buttons

.btn {
    border-radius: 0;
    text-transform: uppercase;
    padding: 15px 40px;
    @include font-size(18); 
    font-weight: 700;
}

.btn-primary {
    color: $white;
    background-color: transparent;
    border-color: $white;

    &:hover, &:focus {
        background: $primary-color;
        border-color: $primary-color;
        box-shadow: none !important;
    }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
}